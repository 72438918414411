import React from 'react';
import { styled } from '@mui/material/styles';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';

const CreditStyled = styled(Box)(({ theme }) => ({
    '&.x-credit-menu': {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginRight: '0.5rem',
    },
    '.x-menu-item': {
        textTransform: 'none',
        '.-text': {
            fontSize: theme.baseVariables.fonts.fontSizeSmallerLarge,
            fontWeight: theme.baseVariables.fonts.fontWeightMedium,
            color: theme.baseVariables.menus.desktopColor,
            transition: 'color .2s',
            [theme.breakpoints.up('lg')]: {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
            },
        },
    },
}));

interface ICredit {
    creditValue: number;
}

const Credit: React.FC<ICredit> = (props) => {
    const { creditValue = 0 } = props;

    return (
        <CreditStyled className={`x-credit-menu`}>
            <Link to={'/account/credit/amount'}>
                <Button key={`menu-item-credit-balance`} className={`x-menu-item`}>
                    <Typography component={'span'} className={'-text'}>
                        <MonetizationOnIcon style={{ paddingTop: '5px' }} />
                    </Typography>

                    <Typography component={'span'} className={'-text'}>
                        <NumericFormat
                            thousandSeparator
                            fixedDecimalScale
                            decimalScale={2}
                            displayType={'text'}
                            value={Number(creditValue)}
                        />
                    </Typography>
                </Button>
            </Link>
        </CreditStyled>
    );
};

export default Credit;
