import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTrans } from '@core/Hook/trans';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import normalizeUrl from "normalize-url";
import { yupResolver } from '@hookform/resolvers/yup';
import PlainButton from '../../Component/Button/PlainButton';
import Form from '@core/Form/Form';
import { IProductInfo } from '../../Recoil/Product/atom';
import isUndefined from 'lodash/isUndefined';
import FormInput from '@core/Form/FormInput';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import { SyntheticInputEvent } from 'react-number-format/types/types';
import {
    AVAILABLE_PLATFORMS,
    AVAILABLE_SERVICES,
    AVAILABLE_TARGET_USERS,
    PRODUCT_CODE_FACEBOOK,
    PRODUCT_CODE_INSTAGRAM,
    PRODUCT_CODE_TIKTOK,
    PRODUCT_CODE_X,
    SERVICE_BOOST_ADD_NOTE,
    SERVICE_BOOST_COMMENT,
    SERVICE_BOOST_COMMENT_POST,
    SERVICE_BOOST_LIKE_POST, SERVICE_BOOST_SHARE_STORY,
    SERVICE_BOOST_VIEW_VIDEO
} from '../../Constants/product';
import OrderSummary from '../../Component/Order/OrderSummary';
import { useResponsive } from '@core/Hook/responsiveHook';
import FileUpload from '../../Component/FileUpload';
import FormLabelControl from '../../Component/Form/FormLabelControl';
import FormActionControl from '../../Component/Form/FormActionControl';
import { useCustomerContext } from '../../Hook/auth';
import { useHandleDialog } from '../../Hook/modalDialogHook';
import LoginIntegration from '../../Component/Modal/LoginIntegration';
import { useManageProductInfo } from '../../Hook/product';

interface IProductForm {
    productInfo: IProductInfo;
    accountName: string;
    accountLink: string;
    handleErrorMessage: any;
}

type SocialAccountLinksType = {
    url: string
}

const ProductFormStyled = styled(Box)(({theme}) => ({
    '&.x-product-form-container': {
        marginTop: theme.spacing(2),
        [theme.breakpoints.between('xxs', 380)]: {
            marginTop: theme.spacing(1.5),
        },
        '.-form-control': {
            paddingTop: theme.spacing(2),
            '&.-hide': {
                display: 'none',
            },
            '.-input-label': {
                '.-product-group-label-required': {
                    marginRight: theme.spacing(1),
                    color: theme.baseVariables.serviceProductForm.requireColor,
                },
                '.-product-group-label': {
                    fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                    color: theme.baseVariables.serviceProductForm.labelColor,
                },
                '.-product-group-sub-label': {
                    fontWeight: theme.baseVariables.fonts.fontWeightNormal,
                    color: theme.baseVariables.serviceProductForm.buttonDownload,
                },
                '&.-upload': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                },
            },
            '.-btn-download-example': {
                marginLeft: theme.spacing(2),
                padding: 0,
                display: 'block',
                float: 'right',
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                fontWeight: theme.baseVariables.fonts.fontWeightNormal,
                color: theme.baseVariables.serviceProductForm.btnDownload,
                background: 'none',
                textDecoration: 'underline',
                '&.-full': {
                    display: 'flex',
                    justifyContent: 'center',
                    margin: theme.spacing(2, 0, 0),
                    float: 'none',
                },
            },
        },
        '.-summary-wrapper': {
            marginBottom: theme.spacing(3),
        },
        '.-button-action-container': {
            background: theme.baseVariables.serviceProductForm.buttonActionContainerBg,
            position: 'fixed',
            bottom: 0,
            left: 0,
            margin: 0,
            zIndex: 3,
            transition: theme.baseVariables.constants.transition.normal,
            width: '100%',
            padding: theme.spacing(2, 4),
            boxShadow: theme.baseVariables.serviceProductForm.buttonActionContainerBoxShadow,
            [theme.breakpoints.up('sm')]: {
                position: 'relative',
                padding: theme.spacing(2, 0),
                boxShadow: 'none',
            },
            '.-button-wrapper': {
                [theme.breakpoints.up('sm')]: {
                    width: '300px',
                    margin: `${theme.spacing(5)} auto`,
                },
                [theme.breakpoints.down('sm')]: {
                    marginTop: theme.spacing(2),
                },
            },
        },
    },
}));

export interface IProductInfoFormData {
    platform: string;
    service: string;
    target: string;
    packageInfo: string;
    productItemFullCode: string;
    amount: number;
    price: number;
    serviceOptionType?: string;
    serviceOptionTypeValue?: string;
    socialAccountName: string | undefined;
    socialAccountLink: string;
    socialAccountLinks: SocialAccountLinksType[];
    socialFileComment?: File | null;
    code?: string;
}

const ProductForm: React.FC<IProductForm> = ({productInfo, accountName, accountLink, handleErrorMessage}) => {
    const {
        platform,
        service,
        target,
        packageInfo,
        serviceOptionType,
        serviceOptionTypeValue
    } = productInfo;
    const {trans} = useTrans();

    const yupUrlValidation = () => {
        let yupValidation = Yup.string().trim()
        const { platform, service } = productInfo;

        if (PRODUCT_CODE_X === platform?.code) {
            yupValidation = yupValidation.matches(/^https:\/\/(www.x.com|x.com)/, 'ไม่ตรงรูปแบบ Url "https://www.x.com"')
        }

        if (PRODUCT_CODE_FACEBOOK === platform?.code) {
            yupValidation = yupValidation.matches(/^https:\/\/(www.facebook.com|facebook.com)/, 'ไม่ตรงรูปแบบ Url "https://www.facebook.com"')
        }

        if (PRODUCT_CODE_TIKTOK === platform?.code) {
            yupValidation = yupValidation.matches(/^https:\/\/(www.tiktok.com|tiktok.com)/, 'ไม่ตรงรูปแบบ Url "https://www.tiktok.com"')
        }

        if (PRODUCT_CODE_INSTAGRAM === platform?.code) {
            yupValidation = yupValidation.matches(/^https:\/\/(www.instagram.com|instagram.com)/, 'ไม่ตรงรูปแบบ Url "https://www.instagram.com"')
            if ([SERVICE_BOOST_LIKE_POST,
                SERVICE_BOOST_COMMENT_POST,
                SERVICE_BOOST_VIEW_VIDEO,
                SERVICE_BOOST_ADD_NOTE,
                SERVICE_BOOST_SHARE_STORY,
            ].includes(service?.code as string)) {
                yupValidation = yupValidation.matches(/^https:\/\/www.instagram.com\/(p|reel|stories)\/+./, 'ไม่ตรงรูปแบบ Url "https://www.instagram.com/reel/xxx", "https://www.instagram.com/p/xxx", "https://www.instagram.com/stories/xxx" ')
            }
        }

        return yupValidation.url(trans('service.product.form.socialAccountLink.valid_url'))
            .required(trans('service.product.form.socialAccountLink.not_blank'))
    }

    const baseSchema = {
        platform: Yup.string().required(trans('service.product.platform.not_blank')).oneOf(AVAILABLE_PLATFORMS),
        service: Yup.string().required(trans('service.product.service.not_blank')).oneOf(AVAILABLE_SERVICES),
        target: Yup.string().required(trans('service.product.target.not_blank')).oneOf(AVAILABLE_TARGET_USERS),
        packageInfo: Yup.string().required(trans('service.product.package.not_blank')),
        price: Yup.number().required(trans('general.form_errors.not_blank')),
        amount: Yup.number().required(trans('general.form_errors.not_blank')),
        productItemFullCode: Yup.string().required(trans('general.form_errors.not_blank')),
        socialAccountName: Yup.string(),
        socialAccountLink: Yup.string()
            .url(trans('service.product.form.socialAccountLink.valid_url'))
            .required(trans('service.product.form.socialAccountLink.not_blank')),
        socialAccountLinks: Yup.array(Yup.object({
            url: yupUrlValidation()
        }).test('uniqueUrl', 'URL นี้ต้องไม่ซ้ำกับค่าอื่น', function (value) {
            if (!value || !value['url']) {
                return true;
            }

            const { path } = this;
            const options = [...this.parent];
            const currentIndex = options.indexOf(value);
            const subOptions = options.slice(0, currentIndex);

            if (subOptions.some((option) => normalizeUrl(option['url'] || '') === normalizeUrl(value['url'] || ''))) {
                throw this.createError({
                    path: `${path}.url`,
                });
            }

            return true;
        })).required(),
    }

    const validationSchemaDefault = Yup.object().shape(baseSchema);
    const validationSchemaWithServiceOptionType = Yup.object().shape({
        ...baseSchema,
        serviceOptionType: Yup.string().required(
            trans('service.product.service_option.not_blank', {
                value: trans(`service.product.service_option.${service?.code}`),
            }),
        ),
        serviceOptionTypeValue: Yup.string().required(
            trans('service.product.service_option_type.not_blank', {
                value: trans(`service.product.service_option_type.${serviceOptionType?.code}`),
            }),
        ),
    });

    const {isTabletOrMobile, isDesktop} = useResponsive();
    const {productChooseInfo, generateServerProductItemFullCode} = useManageProductInfo();
    const shouldValidateServiceOption = !isUndefined(service) && SERVICE_BOOST_COMMENT === service.code;
    const validationSchema = shouldValidateServiceOption ? validationSchemaWithServiceOptionType : validationSchemaDefault;

    const {
        control,
        register,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm<IProductInfoFormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            socialAccountLink: 'https://DEFAULT-VALUES.co.th',
            socialAccountLinks: [{ url: "" }]
        }
    });

    const {
        fields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: "socialAccountLinks",
    });

    const totalPrice = (productChooseInfo.packageInfo?.price || 0) * fields.length
    const [socialAccountName, setSocialAccountName] = useState<string>(accountName);
    const [socialAccountLink, setSocialAccountLink] = useState<string>(accountLink);
    const [socialFileComment, setSocialFileComment] = useState<File | null>(null);
    const navigate = useNavigate();

    const handleInputChange = useCallback(
        debounce((e: SyntheticInputEvent) => {
            const inputName = e.target.name
            const inputValue = e.target.value

            if ('socialAccountName' === inputName) setSocialAccountName(inputValue);
            if ('socialAccountLink' === inputName) setSocialAccountLink(inputValue);
        }, 500),
        [socialAccountLink, socialAccountName],
    );

    const {isLogged} = useCustomerContext();
    const loginModal = useHandleDialog();
    const registerModal = useHandleDialog();

    const submitProduct = (data: IProductInfoFormData) => {
        const resolvedData = {
            ...data,
            price: data.price * data.socialAccountLinks.length,
            socialFileComment: socialFileComment,
        }

        // if ('boost_comment' === service?.code && !socialFileComment) {
        //     setError('File comment is required');
        //
        //     return;
        // }

        navigate('/account/order-summary', {
            state: {
                productInfoFormData: resolvedData,
            },
        });
    };

    const renderButtonActionProduct = () => {
        const buttonAction = isLogged ? (
            <PlainButton
                fullWidth
                type={'submit'}
                color={'primary'}
                label={trans('general.next')}
            />
        ) : (
            <PlainButton
                fullWidth
                color={'primary'}
                label={trans('general.next')}
                onClick={loginModal.handleClickOpen}
            />
        );

        return (
            <FormActionControl button={buttonAction}>
                {isTabletOrMobile && <OrderSummary price={totalPrice} />}
            </FormActionControl>
        );
    };

    const renderButtonUploadFile = () => {
        return (
            <>
                <FolderIcon className={'-icon'}/>
                {trans('service.product.form.socialFileComment.button')}
                <Typography component={'span'} className={'-highlight'}>
                    {trans('service.product.form.socialFileComment.upload')}
                </Typography>
            </>
        );
    };

    const formData: IProductInfoFormData = {
        platform: !isUndefined(platform) ? platform.code : '',
        service: !isUndefined(service) ? service.code : '',
        target: !isUndefined(target) ? target.code : '',
        packageInfo: !isUndefined(packageInfo) ? packageInfo.code : '',
        productItemFullCode: !isUndefined(productChooseInfo.platform) ? generateServerProductItemFullCode() : '',
        price: !isUndefined(productChooseInfo.packageInfo) ? Number(productChooseInfo.packageInfo.price) : 0,
        amount: !isUndefined(productChooseInfo.packageInfo) ? Number(productChooseInfo.packageInfo.amount) : 0,
        serviceOptionType: !isUndefined(serviceOptionType) ? serviceOptionType.code : '',
        serviceOptionTypeValue: !isUndefined(serviceOptionTypeValue) ? serviceOptionTypeValue.code : '',
        socialAccountName: socialAccountName,
        socialAccountLink: socialAccountLink || 'https://CHANGE-ME.com', // NOTE: Old testament and not use, just for validation
        socialAccountLinks: [{ url: socialAccountLink || '' }],
    };

    useEffect(() => {
        Object.keys(formData).map((key) => {
            // @ts-ignore
            setValue(key, formData[key]);
        });
    }, [productInfo]);

    useEffect(() => {
        if (!Object.keys(errors).length) return;
        const messageArr: any[] = [];
        Object.keys(formData).map((key) => {
            if (errors[key] && !includes(['socialAccountName', 'socialAccountLink'], key))
                messageArr.push({
                    [key]: errors[key].message,
                });
        });

        handleErrorMessage(messageArr);
    }, [errors]);

    const downloadUrl = '#0';

    return (
        <ProductFormStyled className="x-product-form-container">
            {isDesktop && (
                <Box className={'-summary-wrapper'}>
                    <OrderSummary price={totalPrice} />
                </Box>
            )}

            <Form
                register={register}
                onSubmit={handleSubmit(submitProduct)}
                buttonComponent={renderButtonActionProduct()}
            >
                <Grid container spacing={{xxs: 1, sm: 2}} columns={12}>
                    {'boost_comment_file' === serviceOptionType?.code && (
                        <Grid item xs={12} className={'-form-control'}>
                            <InputLabel className={'-input-label -upload'}>
                                <FormLabelControl
                                    label={trans(`service.product.form.socialFileComment.label`)}
                                    subLabel={trans(`service.product.form.socialFileComment.sub_label`)}
                                    isRequired
                                />
                                {!isTabletOrMobile && (
                                    <Button href={downloadUrl} className={'-btn-download-example'} variant="text">
                                        {trans(`service.product.form.socialFileComment.download`)}
                                    </Button>
                                )}
                            </InputLabel>
                            <Box className={'-form-control'}>
                                <FileUpload
                                    onChange={() => {
                                    }}
                                    setFile={setSocialFileComment}
                                    customButton={renderButtonUploadFile()}
                                    showButtonClear={false}
                                    acceptedFile={['csv']}
                                    // textError={error}
                                />
                            </Box>
                            {isTabletOrMobile && (
                                <Button href={downloadUrl} className={'-btn-download-example -full'} variant="text">
                                    {trans(`service.product.form.socialFileComment.download`)}
                                </Button>
                            )}
                        </Grid>
                    )}

                    {Object.keys(formData).map((key, index) => {
                        if (['socialFileComment', 'socialAccountName', 'socialAccountLink'].includes(key)) return;

                        const platform = formData.platform;
                        const shouldRenderForm = includes(['socialAccountName', 'socialAccountLink'], key);
                        const hideFormClass = !shouldRenderForm ? '-hide' : '';
                        const inputProps = {
                            hiddenLabel: true,
                            name: key,
                            id: key,
                            className: '-form-control',
                            register,
                            placeholder: trans(`service.product.form.${key}.placeholder_${platform}`),
                            errors: errors,
                            type: shouldRenderForm ? 'text' : 'hidden',
                            onInput: (e: SyntheticInputEvent) => handleInputChange(e),
                        };

                        if ('socialAccountLinks' === key) {
                            return (
                                <Box
                                    key={`${key}-${index}`}
                                    width={'100%'}
                                    maxWidth={520}
                                    marginTop={2}
                                    marginX={"auto"}
                                >
                                    <FormLabelControl
                                        isRequired
                                        label={trans(`service.product.form.${key}.label`)}
                                        subLabel={trans(`service.product.form.${key}.sub_label`)}
                                    />
                                    <Stack spacing={2} alignItems={"center"} marginTop={1}>
                                        {fields.map((item, index) => {
                                            return (
                                                <Box
                                                    position={"relative"}
                                                    key={item.id}
                                                    width={'100%'}
                                                    display={"flex"}
                                                    gap={1}
                                                >
                                                    <TextField
                                                        error={!!errors['socialAccountLinks']?.[index]}
                                                        fullWidth {...register(`socialAccountLinks.${index}.url`)}
                                                        // placeholder={`${index + 1}. ตัวอย่าง https://facebook.com/wowboost`}
                                                        helperText={errors['socialAccountLinks'] ? errors['socialAccountLinks']?.[index]?.url?.message : ''}
                                                    />
                                                    {fields.length !== 1 && (
                                                        <Box marginTop={.25}>
                                                            <Button
                                                                size={"small"}
                                                                color={"error"}
                                                                variant={"outlined"}
                                                                onClick={() => remove(index)}
                                                                sx={{ borderRadius: 999 }}
                                                            >{'ลบ'}</Button>
                                                        </Box>
                                                    )}
                                                </Box>
                                            )
                                        })}

                                        <Stack alignItems={"center"} spacing={1}>
                                            <Button
                                                type="button"
                                                disabled={fields.length >= 50}
                                                size={"small"}
                                                variant={"text"}
                                                color={"primary"}
                                                sx={{textDecoration: 'underline'}}
                                                onClick={() => {
                                                    if (fields.length > 50) return

                                                    append({ url: '' })
                                                }}
                                            >
                                                {'เพิ่มลิงก์'}
                                            </Button>
                                            <Typography
                                                variant={"body2"}
                                                fontStyle={"italic"}
                                            >
                                                {'เพิ่มได้สูงสุด 50 ลิงก์'}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            )
                        }

                        return (
                            <Grid
                                item
                                sm={6}
                                xxs={12}
                                key={`${key}-${index}`}
                                margin={'auto'}
                                className={`-form-control ${hideFormClass}`}
                            >
                                <FormLabelControl
                                    isRequired
                                    label={trans(`service.product.form.${key}.label`)}
                                    subLabel={trans(`service.product.form.${key}.sub_label`)}
                                />
                                <FormInput {...inputProps} />
                            </Grid>
                        );
                    })}
                </Grid>
            </Form>
            <LoginIntegration handleLoginModal={loginModal} handleRegisterModal={registerModal} />
        </ProductFormStyled>
    );
};

export default ProductForm;
