import { selectorFamily, useSetRecoilState } from 'recoil';
import { IPackageData, IPlatformData, IServiceData, IServiceOptionData, IServiceOptionDataSet, ITargetData, ProductsAtom } from './atom';
import { getProducts } from '../../Api/product';
import { IApiState, resolveClientApiState } from '@core/Hook/api';
import { getIconByCode, mapServerProductCodeToClientProductCode } from '../../Constants/product';
import first from 'lodash/first';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
import { loginByPhone } from "../../Api/auth";
import { productDefaultPrepared } from "../../Utils/helper";

export const InitialProductInfoByPlatformCodeSelector = selectorFamily({
    key: 'InitialProductInfoByPlatformCodeSelector',
    get:
        (platformCode?: string | undefined) =>
        ({ get }): any => {
            const products = get(ProductsAtom);

            if (isUndefined(products?.platforms)) {
                return {
                    platform: undefined,
                    service: undefined,
                    target: undefined,
                    packageInfo: undefined,
                    serviceOptionType: undefined,
                    serviceOptionTypeValue: undefined,
                };
            }

            const platform: IPlatformData | undefined = isUndefined(platformCode)
                ? products.platforms && first(products.platforms)
                : first(products.platforms.filter((platform: IPlatformData) => platform.platformCode === platformCode));
            const service =
                products.services &&
                first(products.services.filter((service: IServiceData) => service.platformCode === platform?.platformCode));
            const target =
                products.targets && first(products.targets.filter((target: ITargetData) => target.platformCode === platform?.platformCode));
            const packageInfo =
                products.packagesInfo &&
                first(products.packagesInfo.filter((packageInfo: IPackageData) => packageInfo.platformCode === platform?.platformCode));

            if (isUndefined(platform?.platformCode)) {
                return {
                    platform: undefined,
                    service: undefined,
                    target: undefined,
                    packageInfo: undefined,
                    serviceOptionType: undefined,
                    serviceOptionTypeValue: undefined,
                };
            }

            return {
                platform: {
                    code: platform?.platformCode,
                    label: platform?.platformName,
                    icon: getIconByCode(platform?.platformCode),
                },
                service: {
                    code: service?.platformServiceCode,
                    label: service?.platformServiceCode,
                    icon: getIconByCode(service?.platformServiceCode),
                    description: service?.platformServiceDescription,
                },
                target: {
                    code: target?.targetAccountCode,
                    label: target?.targetAccountName,
                },
                packageInfo: {
                    code: packageInfo?.productItemCode,
                    icon: getIconByCode(packageInfo?.productItemCode),
                    price: packageInfo?.price,
                    amount: packageInfo?.amount,
                },
            };
        },
});

export const InitialProductInfoByPreviousOrderSelector = selectorFamily({
    key: 'InitialProductInfoByPreviousOrderSelector',
    get:
        (productItemFullCode?: string | undefined) =>
        ({ get }): any => {
            const products = get(ProductsAtom);

            if (isUndefined(productItemFullCode)) return undefined;

            const serverProductItemFullCodeArr: Array<string> = (productItemFullCode as string).split('_');
            if (serverProductItemFullCodeArr.length !== 4) return;

            const productInfoKey = ['platformCode', 'serviceCode', 'targetCode', 'packageCode'];
            const productInfoObject: { [p: string]: string } = productInfoKey.reduce(
                (a, key, index) =>
                    Object.assign(a, { [key]: mapServerProductCodeToClientProductCode(serverProductItemFullCodeArr[index]) }),
                {},
            );

            const platform: IPlatformData | undefined = find(products.platforms, { platformCode: productInfoObject.platformCode });
            const service: IServiceData | undefined = find(products.services, {
                platformServiceCode: productInfoObject.serviceCode,
                platformCode: productInfoObject.platformCode,
            });
            const target: ITargetData | undefined = find(products.targets, {
                platformCode: productInfoObject.platformCode,
                platformServiceCode: productInfoObject.serviceCode,
                targetAccountCode: productInfoObject.targetCode,
            });
            const packageInfo: IPackageData | undefined = find(products.packagesInfo, {
                platformCode: productInfoObject.platformCode,
                platformServiceCode: productInfoObject.serviceCode,
                targetAccountCode: productInfoObject.targetCode,
                productItemCode: productInfoObject.packageCode,
            });

            if (isUndefined(platform) || isUndefined(service) || isUndefined(target) || isUndefined(packageInfo)) return undefined;

            return productDefaultPrepared(platform, service, target, packageInfo);
        },
});

export const useFetchProducts = () => {
    const setProducts = useSetRecoilState(ProductsAtom);

    return async (apiState: IApiState, setApiState: (...args: any[]) => void) => {
        setApiState({ ...apiState, isLoading: true });

        const productResponse = await getProducts();
        const productResponseResolved = resolveClientApiState(productResponse, true);
        const platforms: IPlatformData[] = [];
        const services: IServiceData[] = [];
        const serviceOptions: IServiceOptionData[] = [];
        const targets: ITargetData[] = [];
        const packagesInfo: IPackageData[] = [];

        productResponseResolved.data.map((p: any) => {
            platforms.push({
                platformCode: mapServerProductCodeToClientProductCode(p.platformCode),
                platformName: p.platformName,
            });

            p.serviceList.map((serviceItem: any) => {
                services.push({
                    platformServiceCode: mapServerProductCodeToClientProductCode(serviceItem.platformServiceCode),
                    platformServiceName: serviceItem.platformServiceName,
                    platformCode: mapServerProductCodeToClientProductCode(p.platformCode),
                    platformServiceDescription: serviceItem.platformServiceDescription,
                    platformServiceOption: serviceItem.platformServiceOption,
                });

                if (serviceItem.platformServiceOption) {
                    serviceItem.platformServiceOption.map((platformServiceOption: any) => {
                        const dataSetMapClintCode: IServiceOptionDataSet[] = platformServiceOption.dataSet.map((data: any) => {
                            return {
                                setName: data.setName,
                                setCode: mapServerProductCodeToClientProductCode(data.setCode),
                            };
                        });

                        serviceOptions.push({
                            platformCode: mapServerProductCodeToClientProductCode(p.platformCode),
                            platformServiceCode: mapServerProductCodeToClientProductCode(serviceItem.platformServiceCode),
                            name: platformServiceOption.name,
                            dataSet: dataSetMapClintCode,
                            typeCode: mapServerProductCodeToClientProductCode(platformServiceOption.typeCode),
                        });
                    });
                }

                serviceItem.productList.map((target: any) => {
                    targets.push({
                        platformServiceCode: mapServerProductCodeToClientProductCode(serviceItem.platformServiceCode),
                        platformServiceName: serviceItem.platformServiceName,
                        targetAccountCode: mapServerProductCodeToClientProductCode(target.targetAccountCode),
                        targetAccountName: target.targetAccountName,
                        platformCode: mapServerProductCodeToClientProductCode(p.platformCode),
                    });

                    target.productItemList.map((packageInfo: any) => {
                        packagesInfo.push({
                            platformServiceCode: mapServerProductCodeToClientProductCode(serviceItem.platformServiceCode),
                            platformServiceName: serviceItem.platformServiceName,
                            targetAccountCode: mapServerProductCodeToClientProductCode(target.targetAccountCode),
                            targetAccountName: target.targetAccountName,
                            platformCode: mapServerProductCodeToClientProductCode(p.platformCode),
                            ...packageInfo,
                            productItemCode: packageInfo.productItemCode,
                        });
                    });
                });
            });
        });

        setApiState({ ...apiState, isLoading: false });

        setProducts({
            platforms: platforms,
            services: services,
            serviceOptions: serviceOptions,
            targets: targets,
            packagesInfo: packagesInfo,
        });
    };
};
